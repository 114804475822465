
import {computed, defineComponent, ref} from "vue";
import Entities from "@/components/base/common/Entities.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import UserStatus from "@/views/user/UserStatus.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import {AdminCompanySelect, PinaFilterObjects} from "@/core/composite/composite";
import {useAdminUserStore} from "@/store/admin/admin.user.store";
import UserService from "@/core/services/UserService";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import BaseSelect from "@/components/base/select/BaseSelect.vue";

export default defineComponent({
  name: "Users",
  components: {BaseSelect, QuickAction, UserStatus, DateTimeFormat, KtDatatable, Entities},
  setup() {
    const userAdminStore = useAdminUserStore();
    const filterObjects = ref({companyId: ''})
    const page = computed(() => userAdminStore.page);
    const tableHeader = ref([
      {
        name: "FullName",
        key: "fullName",
        sortable: true,
      },
      {
        name: "Last Login",
        key: "lastLogin",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]);
    return {
      filterObjects,
      page,
      ...PinaFilterObjects(userAdminStore, filterObjects.value, []),
      tableHeader,
      userAdminStore,
      ...AdminCompanySelect()
    }
  },
  methods: {
    searchEvent() {
      this.updateFilterObject(this.filterObjects);
    },
    discardEvent() {
      this.filterObjects.companyId = '';
      this.updateFilterObject(this.filterObjects);
    },
    async onSwitch(user) {
      await UserService.impersonal(user.name).then(res => {
        JwtService.setOldToken();
        JwtService.saveToken(res.accessToken);
        ApiService.setHeader();
        this.userAdminStore.setImpersonal(true)
        this.$router.push("/home");
      })
    },
  }
})
